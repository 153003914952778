import { ChakraProvider } from '@chakra-ui/react'
import type { AppProps as AppPropsType } from 'next/app'
import { appWithTranslation } from 'next-i18next'
import Layout from 'components/layouts/main'
import Fonts from 'components/fonts'
import theme from 'lib/theme'
// import { AnimatePresence } from 'framer-motion'

function Website(appProps: AppPropsType) {
  const { Component, pageProps, router } = appProps
  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <Layout router={router}>
        {/* <AnimatePresence exitBeforeEnter initial={true}> */}
        <Component {...pageProps} key={router.route} />
        {/* </AnimatePresence> */}
      </Layout>
    </ChakraProvider>
  )
}

export default appWithTranslation(Website)
