import { useRouter } from 'next/router'
import Link from 'next/link'

type ActiveLinkPropsType = {
  children: React.ReactElement
  href: string
}

const ActiveLink: React.FC<ActiveLinkPropsType> = ({ children, href }) => {
  const router = useRouter()
  const style: React.CSSProperties = {
    color: router.asPath.includes(href) ? '#000' : '#a9aeb6'
  }

  return (
    <span style={style}>
      <Link href={href}>{children}</Link>
    </span>
  )
}

export default ActiveLink
