import React from 'react'
import Head from 'next/head'
import Footer from 'components/footer'
import Header from 'components/header'
import { Box, Container } from '@chakra-ui/react'
import { Router } from 'next/router'

type MainPropsType = {
  children: React.ReactElement
  router: Router
}

const Main: React.FC<MainPropsType> = props => {
  const { children } = props
  return (
    <Box maxW="container.md" margin="0 auto" as="main" pb={8}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>My Page - Homepage</title>
      </Head>
      <Header />
      <Container maxW="container.md" mb="60px">
        {children}
      </Container>
      <Footer />
    </Box>
  )
}

export default Main
