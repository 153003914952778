import { Icon as ReactIcon } from '@iconify/react'
import ActiveLink from './active-link'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  height: 58px;
  background-color: #fff;
  max-width: 768px;
  margin: 0 auto;
  z-index: 100;
`

const Icon = styled(ReactIcon)`
  width: 30px;
  height: 30px;
  color: inherit;
  transition: color 0.4s ease;
`

const Footer = () => {
  return (
    <Wrapper>
      <ActiveLink href="/main/">
        <Icon icon="mdi-light:home" />
      </ActiveLink>
      <ActiveLink href="/gallery/">
        <Icon icon="mdi-light:picture" />
      </ActiveLink>
      <ActiveLink href="/contacts/">
        <Icon icon="mdi-light:comment-text" />
      </ActiveLink>
      <ActiveLink href="/price/">
        <Icon icon="mdi-light:tag" />
      </ActiveLink>
    </Wrapper>
  )
}

export default Footer
