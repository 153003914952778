import { Box, Center } from '@chakra-ui/react'

const Header = () => {
  return (
    <Box
      width="100%"
      height={65}
      position="relative"
      className="text"
      style={{ fontFamily: 'Italiana' }}
    >
      <Center h="80px" fontSize="1.875rem">
        Tashhhka
      </Center>
    </Box>
  )
}

export default Header
